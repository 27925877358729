@import "~ng-pick-datetime/assets/style/picker.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html, body {
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
}
.cdk-overlay-container{
  z-index: 1051 !important;
}
a:hover {
  text-decoration: none;
}

.navbarCustom {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.navbarCustom .navbar-nav .nav-item .nav-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #1e494f;
  font-weight: 400;
  letter-spacing: 1px;
}

.navbarCustom .navbar-nav .nav-item.active .nav-link, .navbarCustom .navbar-nav .nav-item:hover .nav-link {
  color: #41c4dd;
}

.heroSlider {
  background-color: #41c4dd;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroSlider .carousel-control-next,
.heroSlider .carousel-control-prev {
  width: 5%;
}

.heroSlider .carousel-inner {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heroSlider h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.heroSlider h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  color: #1e494f;
  font-weight: 400;
  letter-spacing: 2px;
}

.heroSlider .imgBG {
  background-color: #fff;
  padding: 15px;
  border-radius: 30px;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.heroSlider .imgBG img {
  height: 300px;
  border-radius: 5px;
  width: 100%;
}

.btnPrimary {
  background-color: #41c4dd;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
}

.btnPrimary:hover {
  background-color: #f68c38;
}

.btnWhite {
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #41c4dd;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  border: solid 1px #41c4dd;
}

.btnWhite:hover {
  background-color: #41c4dd;
  border: solid 1px #41c4dd;
}

.btnBSuccess {
  background-color: #00aa00;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnBSuccess:hover {
  background-color: #00aa00;
}

.btnDanger {
  background-color: #cf0101;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnDanger:hover {
  background-color: #cf0101;
}

.bgWhite {
  background-color: #fff;
}

.bgPrimary {
  background-color: #41c4dd;
}

.bgDark {
  background-color: #1e494f;
}

.py-80 {
  padding: 40px 0;
}

.icon svg path {
  fill: #000;
}

.mb80 {
  margin-bottom: 80px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  color: #41c4dd;
  font-weight: 700;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  color: #000;
  font-weight: 700;
}

p {
  font-family: 'Montserrat', sans-serif;
  /* font-size: 16px;
  color: #000;
  font-weight: 400; */
}

.colorWhite {
  color: #fff;
}

.font18 {
  font-size: 18px;
}

.latestSermonsBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.latestSermonsBox .title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.latestSermonsBox p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

footer {
  padding: 50px 0 20px 0;
}

footer h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}

footer a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

footer a:hover {
  color: #1e494f;
}

footer p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

footer hr {
  border-top: solid 1px rgba(255, 255, 255, 0.4);
}

footer .font12 {
  font-size: 12px;
  font-weight: 400;
}

.heroBanner {
  margin-top: 75px;
  padding: 50px 0;
}

.heroBanner h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.heroBanner .videoFrame {
  background-color: #fff;
  padding: 15px;
  border-radius: 30px;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.coursePart {
  background-color: #1e494f;
  padding: 30px;
  border-radius: 10px;
}

.coursesBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.coursesBox .courseBoxImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #fff;
  margin: 0 auto;
}

.coursesBox .courseBoxImg img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.coursesBox .title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.coursesBox p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.list ul {
  padding: 0;
  margin: 0;
}

.list ul li {
  list-style-type: none;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.list ul li span {
  width: 40px;
}

.list ul li span img {
  width: 24px;
}

.socialMediaBox {
  background-color: #fff;
  padding: 50px 15px;
  border-radius: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.socialMediaBox .icon img {
  width: 100px;
}

.socialMediaBox .title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}

.socialMediaBox:hover {
  background-color: #41c4dd;
}

.socialMediaBox:hover .title {
  color: #fff;
  text-decoration: none !important;
}

.methodBG {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.imgFrame {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.audiorowPart {
  background-color: #41c4dd;
  padding: 15px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 20px;
}

.audiorowPart h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.audiorowPart .audioImg {
  width: 120px;
  max-width: 120px;
}

.audiorowPart .audioImg img {
  border-radius: 6px;
}

.audiorowPart .audioDetails {
  width: calc(100% - 140px);
  padding-left: 20px;
}

.audiorowPart .playBtn {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.loginMain {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
}

.loginMain .login {
  width: 320px;
}

.loginMain .login .logo {
  margin-bottom: 10px;
}

.loginMain .login .loginBox {
  background-color: #1e494f;
  padding: 15px;
  border-radius: 10px;
}

label {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #f5f5f5;
  font-weight: 400;
  margin-bottom: 5px;
}

.fontBlk {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.input {
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  border: solid 1px #41c4dd;
}

.input:focus {
  color: #000;
}

.linkWhite {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.linkWhite:hover {
  color: #f68c38;
}

.sidebar {
  background-color: #f5f5f5;
  width: 300px;
  height: calc(100vh - 75px);
  position: fixed;
  left: 0;
  top: 75px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  z-index: 99;
}

.sidebar ul {
  padding: 0;
  margin: 20px 0 0 0;
  width: 100%;
}

.sidebar ul li {
  margin-bottom: 10px;
  list-style-type: none;
}

.sidebar ul li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
}

.sidebar ul li a:hover, .sidebar ul li a.active {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  background-color: #41c4dd;
  border-radius: 30px;
}

.nodata {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-align: center;
}

.profile-pic {
  color: transparent;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  transition: all .3s ease;
}

.profile-pic input {
  display: none;
}

.profile-pic img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  width: 165px;
  height: 165px;
  -webkit-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
          box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
  margin-bottom: 0 !important;
}

.profile-pic:hover .-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0 !important;
}

.profile-pic span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: .2em;
  height: 2em;
}

.alertPart {
  background-color: #cf0101;
  margin-top: 75px;
  padding: 5px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 992px) {
  .sidebar {
    width: 100%;
    position: fixed;
    top: 75px;
    border-radius: 0;
    height: auto;
    z-index: 99;
  }
  .sidebar .logo {
    display: none;
  }
  .sidebar ul {
    margin: 0;
  }
}

.navbarTogglerCustom {
  background-color: #41c4dd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.navbarTogglerCustom span {
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin: 4px 0;
}

.navbarTogglerCustom:hover {
  background-color: #41c4dd;
}

.navbarTogglerCustom:hover span {
  background-color: #000;
}

.mobileMenu {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  background-color: #41c4dd;
  z-index: 99;
}

.mobileMenu .navbar-nav .nav-link {
  color: #fff;
  padding: 20px;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #000;
}

.userPic {
  width: 50px;
  height: 50px;
}

.userPic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.nav-item {
  margin-left: 10px;
}

.dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.middlePartMain {
  width: 100%;
  padding-left: 300px;
  padding-top: 75px;
}

.error-text {
  color:orangered;
}

.middlePartMain .middle {
  padding: 30px;
}

.discipleshipTitle {
  padding-top: 80px;;
}

.btnDiscipled {
    text-decoration: white;
}

.discipleshipColor {
    color: rgb(237, 237, 237);
}

.hardshadow {
  text-shadow: 0 13.36px 8.896px black, 0 -2px 1px black;
  letter-spacing: -4px;
  color: black;
  text-align: center;
}

@media (max-width: 992px) {
  .heroSlider h1 {
    font-size: 35px;
  }
  .heroSlider h2 {
    font-size: 24px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  .heroBanner h2 {
    font-size: 24px;
  }
  .userButton {
    padding: 0;
    border: 0;
  }
  .middlePartMain {
    padding-left: 0;
  }
  .dropdown {
    display: unset;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (max-width: 576px) {
  .audiorowPart .audioImg {
    width: 100%;
    margin-bottom: 10px;
  }
  .audiorowPart .audioDetails {
    width: 100%;
    padding-left: 0;
  }
}
/*# sourceMappingURL=style.css.map */

.linkDark {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #41c4dd;
  font-weight: 700;
}

.linkDark:hover {
  color: #f68c38;
}

.recentEvemtPart {
  border-bottom: solid 1px #f5f5f5;
}

.recentEvemtPart .datepart .month {
  background-color: #41c4dd;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.recentEvemtPart .datepart .date {
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  color: #1e494f;
  font-weight: 700;
  text-align: center;
}

.recentEvemtPart .detailspart h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
